@import "styles/tools";

.bannerCarousel {
  $this: &;
  @include container;
  @include vertical-spacing;

  @include above(md) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: rem(24);
  }

  &__heading {
    @include typeHeadline(24);
    display: block;
    grid-column: 1/ 13;
    color: token("secondary-on-general-light");
    margin-bottom: rem(24);

    @include above(md) {
      @include typeHeadline(32);
      margin-bottom: rem(0);
    }
  }

  &__inner {
    grid-column: 1 / 13;

    &:not(&.loaded) {

      // hide until carousel scaffolded to stop content jump
      #{$this}__slideWrapper:not(:first-child) {
        display: none;
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
  }
}